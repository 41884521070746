
export const environment = {
  production: "false",
  gs1BaseUrl: "https://www.gs1.org/",
  gs1PPUrl: "na",
  gs1VBGUrl: "https://api-dv.gs1.org/vbg-st/v1",
  gs1GRPUrl: "https://api-dv.gs1.org/vbg-st/spa/v3",
  ssoAuthority: "https://gs1sso-dv2.gs1.org",
  ssoClientId: "GDOSCUIDEV",
  ssoSilentRefreshUri: "https://gdosc-dv.gs1.org/assets/silent-refresh.html",
  ssoRedirectUri: "https://gdosc-dv.gs1.org/auth-callback",
  ssoPostLogoutRedirectUri: "https://gdosc-dv.gs1.org",
  ssoResponseType: "code",
  ssoScope: "openid profile azure-apim-sso-jwt",
  exportFileName: "exportresult.json",
  gaTrackingId: "G-FJWTLKY1CY",
  checkdigit_Calculator_Url: "https://www.gs1.org/services/check-digit-calculator",
  cdnUrl: "https://endpoint-euw-qa-gdosc-01-dvaegaf4f2a5ddhg.z01.azurefd.net",
};