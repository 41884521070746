export class CRMUserProfile {
  AddressCountry: string = "";
  CloudInvitationDate: string = "";
  CloudRegID: string = "";
  CloudRegStatus: string = "";
  CloudUPMRole: string = "";
  CompanyAddressCountry: string = "";
  CompanyGLN: string = "";
  CompanyID: string = "";
  CompanyIndustry: string = "";
  CompanyMO: string = "";
  CompanyName: string = "";
  Email: string = "";
  FirstName: string = "";
  GPCLanguage: string = "";
  GPCRole: string = "";
  GPCStatus: string = "";
  HCPPRole: string = "";
  HCPPStatus: string = "";
  HasAccessActivate: string = "";
  LMSCompanyID: string = "";
  LMSLang: string = "";
  LMSRole: string = "";
  LMSStatus: string = "";
  LastName: string = "";
  MOID: string = "";
  Modified: string = "";
  SSORole: string = "";
  SecurityGroups: string = "";
  TRDBRole: string = "";
  TRDBStatus: string = "";
  TRDBCountry: string[] = [];
  TimeZone: string = "";
  UserID: string = "";
  amr: string[] =[];
  auth_time: string = "";
  idp: string = "";
  name: string = "";
  preferred_username: string = "";
  s_hash: string = "";
  sid: string = "";
  sub: string = "";
  token: string = "";
  GDOSCLanguage : string[]=[];
  GDOSCDefaultLanguage : string = "";
  GDOSCTOUAcceptanceDate : Date;
  GDOSTOUVersion : string = "";
  HasAccessVbg:string="";
  ActivateIssuanceTypes: string[] =[];
  ActivateRegStatus: string="";
}
